html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ant-table-wrapper{
  margin-top: 16px;
  margin-bottom: 16px;
}

.ant-pagination{
  float: right;
}

.antd-user-group-card .ant-card-body{
  padding: 24px 12px;
}

.ant-modal-body {
  overflow: hidden;
}

.ant-modal-body .ant-btn {
  margin-right: 10px;
}

.ant-table-row .ant-form-item {
  margin-bottom: 0px;
}

.ant-form-item-control > .ant-form-item:last-child, .ant-form-item [class^="ant-col-"] > .ant-form-item:only-child{
  margin-bottom: 0px !important;
}

.ant-modal-body {
  position: relative;
}

.ant-layout-sider-collapsed .logo_title {
  display: none;
}


.logo_box {
  background: rgba(255, 255, 255, .2);
  margin: 10px;
  font-size: 16px;
  color: #ffffff;
  padding: 10px;
  line-height: 40px;
  width: 180px;
}
.logo_box img {
  width: 30px;
  margin-right: 8px;
}

.gray {
  background: #f5f5f5;
  color: #b5aeae;
}